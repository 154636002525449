import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-youcan-link-video',
  standalone: true,
  templateUrl: './youcan-link-video.component.html',
  styleUrls: ['./youcan-link-video.component.scss'],
})
export class YoucanLinkVideoComponent {
  private _dialog = inject(MatDialog);

  closeVideoDialog() {
    this._dialog.closeAll();
  }
}
